import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { SimpleNewsletterForm } from 'components/SimpleNewsletterForm';
import { NewsletterSegment } from 'model/freeUpdates';
import { useCookies } from 'react-cookie';

const getScrollVerticalLimit = (document: Document): number => {
  // Source: https://javascript.info/size-and-scroll-window
  const verticalLimit =
    Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    ) - document.documentElement.clientHeight;

  if (verticalLimit <= 0) {
    return 1;
  }
  return verticalLimit;
};

const getCurrentScrollPercentage = (scrollPosition: number, scrollVerticalLimit: number) => {
  return (scrollPosition * 100) / scrollVerticalLimit;
};

const MODAL_COOKIE = 'modal';
const ONE_WEEK_IN_SECONDS = 604800;
const SHOW_POPUP_AT_PERCENTAGE = 55;
const UPDATE_INTERVAL_IN_MILISECONDS = 5000;

export const PopUpModal: React.FC = () => {
  const isMounted = useRef(false);
  const [intervalTimeout, setIntervalTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const [shouldShowModal, setShouldShouldModal] = useState(false);
  const [hasModalBeenSHown, setHasModalBeenShown] = useState(false);

  const [cookies, setCookies] = useCookies([MODAL_COOKIE]);

  const updateScrollPositionAndShouldModalIfNeeded = () => {
    if (isMounted.current) {
      if (getCurrentScrollPercentage(window.pageYOffset, getScrollVerticalLimit(document)) > SHOW_POPUP_AT_PERCENTAGE) {
        setShouldShouldModal(true);
        setCookies(MODAL_COOKIE, true, { maxAge: ONE_WEEK_IN_SECONDS });
      }
    }
  };

  const disableModalIfWeHaveACookie = () => {
    if (cookies.modal) {
      setHasModalBeenShown(true);
    }
  };
  useEffect(disableModalIfWeHaveACookie, []);

  const registerModalUpdateInterval = () => {
    isMounted.current = true;
    setIntervalTimeout(setInterval(updateScrollPositionAndShouldModalIfNeeded, UPDATE_INTERVAL_IN_MILISECONDS));

    return () => {
      isMounted.current = false;
      intervalTimeout && clearInterval(intervalTimeout);
    };
  };
  useEffect(registerModalUpdateInterval, []);

  const dismissModalAndClearInterval = () => {
    setHasModalBeenShown(true);
    intervalTimeout && clearInterval(intervalTimeout);
  };

  return (
    <Modal show={shouldShowModal && !hasModalBeenSHown} onHide={dismissModalAndClearInterval}>
      <Modal.Header closeButton>
        <h3>Enjoying The Article?</h3>
      </Modal.Header>
      <Modal.Body>
        <p>Get free updates directly into your inbox! Start by filling the form below:</p>
        <SimpleNewsletterForm segment={NewsletterSegment.ArticlePopUp} />
      </Modal.Body>
    </Modal>
  );
};
