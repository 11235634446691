import React from 'react';
import Img from 'gatsby-image';

import { timeToReadEstimate } from 'components/BlogPostHeader/timeToReadEstimate';
import { AddLinkIfSlug } from 'components/BlogPostHeader/AddLinkIfSlug';

import 'components/BlogPostHeader/BlogPostHeader.scss';

interface FeatureProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fluidFeatureImage: any;
  slug?: string;
  title: string;
  wordCount: number;
}

export const BlogPostHeader: React.FC<FeatureProps> = (props: FeatureProps) => {
  return (
    <>
      <AddLinkIfSlug
        element={
          <Img
            className="blog-post-card__pic"
            fluid={props.fluidFeatureImage.fluid}
            sizes={props.fluidFeatureImage.sizes}
            alt={props.title}
          />
        }
        slug={props.slug}
      />
      <section className="section section-featured">
        <div className="container">
          <div className="blog-post-card m-0">
            <div className="blog-post-card__body">
              <div className="blog-post-card__time-investment">
                Time investment: {timeToReadEstimate(props.wordCount)}
              </div>
              <AddLinkIfSlug element={<h1 className="blog-post-card__title">{props.title}</h1>} slug={props.slug} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

BlogPostHeader.defaultProps = {
  slug: undefined,
};
