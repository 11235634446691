import React from 'react';
import { graphql } from 'gatsby';
import { seoContext, SeoContextArgs } from 'context/seo-context';
import { BlogPostHeader } from 'components/BlogPostHeader';
import { PopUpModal } from 'components/PopUpModal/PopUpModal';
import Layout from '../components/Layout';

interface BlogPostProps {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        date: string;
        title: string;
        featuredImage: {
          childImageSharp: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            fluid: any;
          };
        };
        wordCount: number;
      };
    };
  };
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          childImageSharp {
            fluid(
              quality: 100
              maxWidth: 2000
              srcSetBreakpoints: [200, 340, 520, 890, 992, 1200, 1340, 1520, 1890, 2000]
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        wordCount
      }
    }
  }
`;

const BlogPost: React.FC<BlogPostProps> = (props: BlogPostProps) => {
  const { title, wordCount } = props.data.markdownRemark.frontmatter;
  const fluidFeatureImage = props.data.markdownRemark.frontmatter.featuredImage.childImageSharp;

  const DEFAULT_FEATURE_IMAGE_WIDTH = 2000;
  const DEFAULT_FEATURE_IMAGE_HEIGHT = 541;

  const seoContextArguments: SeoContextArgs = {
    title,
    featuredImagePath: fluidFeatureImage.fluid.src,
    width: DEFAULT_FEATURE_IMAGE_WIDTH,
    height: DEFAULT_FEATURE_IMAGE_HEIGHT,
  };

  return (
    <>
      <seoContext.Provider value={seoContextArguments}>
        <Layout>
          <PopUpModal />
          <article>
            <BlogPostHeader title={title} fluidFeatureImage={fluidFeatureImage} wordCount={wordCount} />
            <section className="section section-content">
              <div className="container">
                <div className="center">
                  <div className="content">
                    {/* eslint-disable-next-line react/no-danger */}
                    <section dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }} />
                  </div>
                </div>
              </div>
            </section>
          </article>
        </Layout>
      </seoContext.Provider>
    </>
  );
};

export default BlogPost;
