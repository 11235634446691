// Vinicio - information taken from wikipedia as reading speed for learning
const SLOW_READING_SPEED = 100;
const FAST_READING_SPEED = 200;

export const timeToReadEstimate = (wordCount: number): string => {
  if (isNaN(wordCount) || wordCount <= 0) {
    throw new Error('__ERROR__ Invalid argument. wordCount must be greater than zero');
  }

  const fastEstimate = wordsToMinuteEstimate({ wordCount, wordsPerMinute: FAST_READING_SPEED });
  const slowEstimate = wordsToMinuteEstimate({ wordCount, wordsPerMinute: SLOW_READING_SPEED });

  return `${fastEstimate} to ${slowEstimate} ${minOrMins(slowEstimate)}`;
};

const minOrMins = (minutes: number): string => {
  if (minutes > 1) {
    return 'minutes';
  }
  return 'minute';
};

const wordsToMinuteEstimate = ({
  wordCount,
  wordsPerMinute,
}: {
  wordCount: number;
  wordsPerMinute: number;
}): number => {
  return Math.round(wordCount / wordsPerMinute);
};
