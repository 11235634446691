import React from 'react';
import { Link } from 'gatsby';

interface AddLinkIfSlugProps {
  element: JSX.Element;
  slug?: string;
}

export const AddLinkIfSlug: React.FC<AddLinkIfSlugProps> = (props: AddLinkIfSlugProps) => {
  return props.slug ? <Link to={props.slug}>{props.element}</Link> : props.element;
};

AddLinkIfSlug.defaultProps = {
  slug: undefined,
};
